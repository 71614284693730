<template>
  <a-modal
    :get-container="modalRef"
    v-model:visible="visible"
    :mask-closable="false"
    title="添加车位"
    :after-close="afterClose"
  >
    <template #footer>
      <a-button :disabled="disabled" @click="visible = false">取消</a-button>
      <a-button :disabled="disabled" type="primary" @click="doSubmit"
        >确定</a-button
      >
    </template>
    <a-form :label-col="{ span: 4 }">
      <a-form-item label="泊位编号" v-bind="validateInfos.code">
        <a-input
          v-model:value="parkingPlaceVo.code"
          :maxlength="16"
          placeholder="泊位编号"
          allow-clear
        ></a-input>
      </a-form-item>
      <a-form-item label="所属车场" v-bind="validateInfos.parkingLotId">
        <a-select
          v-model:value="parkingPlaceVo.parkingLotId"
          notFoundContent="暂无数据"
          placeholder="请选择"
          allow-clear
        >
          <a-select-option
            v-for="item in parkingLotList"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { ref, onMounted, toRefs } from "vue";
import useValidateRules from "@/hooks/useValidateRules";
import useMessage from "@/hooks/useMessage";
import { Form } from "ant-design-vue";
import { save } from "@/api/parking/parkingPlaceApi";
import useParkingPlace from "../../../../hooks/parking/useParkingPlace";

const useForm = Form.useForm;
export default {
  name: "Add",
  props: ["modalRef"],
  emits: ["close"],
  setup(pros, { emit }) {
    const disabled = ref(false);
    const { getParkingLotList, parkingLotList, parkingPlaceVo, placeStates } =
      useParkingPlace();
    const { parkingPlaceVoRules } = useValidateRules();
    const { showErr, showConfirm, showLoading, closeLoading, showTipMessage } =
      useMessage();
    const { validateInfos, validate } = useForm(
      parkingPlaceVo,
      parkingPlaceVoRules,
    );
    const visible = ref(true);
    let refresh = false;
    const afterClose = () => {
      emit("close", refresh);
    };
    const doSubmit = () => {
      validate().then(async () => {
        let action = await showConfirm("添加新的车位?");
        if (action) {
          disabled.value = true;
          await showLoading();
          try {
            let obj = {
              code: parkingPlaceVo.code,
              parkingLotId: parkingPlaceVo.parkingLotId
            };
            await save(obj);
            await closeLoading();
            await showTipMessage("添加成功");
            refresh = true;
            visible.value = false;
          } catch (e) {
            await closeLoading();
            await showErr(e);
            disabled.value = false;
          }
        }
      });
    };
    onMounted(async () => {
      getParkingLotList();
    });
    return {
      ...toRefs(pros),
      disabled,
      visible,
      afterClose,
      getParkingLotList,
      parkingLotList,
      parkingPlaceVo,
      placeStates,
      validateInfos,
      doSubmit,
    };
  },
};
</script>

<style scoped></style>
