import axios from "axios";

export const listAll = parkingLotId => {
  return axios.get("/parking/place/listAll?parkingLotId=" + parkingLotId);
};

export const list = (params, page, pageSize) => {
  return axios.get("/parking/place/list", {
    params: {
      ...params,
      page,
      pageSize,
    },
  });
};

export const save = vo => {
  return axios.post("/parking/place/add", vo);
};

export const detail = id => {
  return axios.get(`/parking/place/${id}/detail`);
};

export const edit = (id, vo) => {
  return axios.put(`/parking/place/${id}/edit`, vo);
};

export const deleteParkingPlace = id => {
  return axios.delete(`/parking/place/${id}/del`);
};
