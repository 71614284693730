import { reactive, ref } from "vue";
import { list } from "@/api/parking/parkingPlaceApi";
import useMessage from "@/hooks/useMessage";
import { listAll } from "../../api/parking/parkingLotApi";
const useParkingPlace = () => {
  const { showErr } = useMessage();
  const loading = ref(false);
  const dataList = reactive({ list: [] });
  const total = ref(0);
  const parkingLotList = reactive([]);
  const placeStates = ref([
    { value: 1, label: "有车" },
    { value: 0, label: "无车" },
  ]);
  const parkingPlaceVo = reactive({
    code: "",
    parkingLotId: null,
  });

  const columns = [
    {
      title: "序号",
      align: "center",
      width: "80px",
      customRender: ({ index }) => {
        return index + 1;
      },
    },
    {
      title: "泊位编号",
      align: "center",
      dataIndex: "code",
    },
    {
      title: "所属车场名称",
      align: "center",
      dataIndex: "parkingLotName",
    },
    {
      title: "启用日期",
      align: "center",
      dataIndex: "createTime",
    },
    {
      title: "状态",
      align: "center",
      dataIndex: "state",
      slots: { customRender: "state" },
    },
    {
      title: "操作",
      align: "center",
      width: "240px",
      slots: { customRender: "operation" },
    },
  ];
  const loadData = async (page, pageSize) => {
    loading.value = true;
    try {
      let res = await list(params, page, pageSize);
      dataList.list = res.data;
      total.value = res.total;
      loading.value = false;
    } catch (e) {
      loading.value = false;
      await showErr(e);
    }
  };

  const getParkingLotList = async () => {
    try {
      let { data } = await listAll();
      parkingLotList.push(...data);
    } catch (e) {
      showErr(e);
    }
  };

  const params = reactive({
    code: "",
    parkingLotId: null,
    state: null,
  });
  return {
    loading,
    params,
    dataList,
    total,
    columns,
    loadData,
    getParkingLotList,
    parkingPlaceVo,
    parkingLotList,
    placeStates
  };
};

export default useParkingPlace;
